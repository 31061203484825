import {useEffect, useRef} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import Team from "./team";

gsap.registerPlugin(ScrollTrigger);

export default function WhoUs() {
    const titleRef = useRef(null);
    const introTextRef = useRef(null);
    const textBlocksRef = useRef([]);
    const paragraphRef = useRef(null);

    useEffect(() => {
        // Title animation
        gsap.fromTo(
            titleRef.current,
            {opacity: 0, y: 50},
            {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: "power3.out",
                scrollTrigger: {
                    trigger: titleRef.current,
                    start: "top 80%",
                    toggleActions: "play none none none",
                },
            }
        );

        // Introductory paragraph animation
        gsap.fromTo(
            introTextRef.current,
            {opacity: 0, y: 50},
            {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: "power3.out",
                scrollTrigger: {
                    trigger: introTextRef.current,
                    start: "top 80%",
                    toggleActions: "play none none none",
                },
            }
        );

        // Animate each text block
        gsap.fromTo(
            textBlocksRef.current,
            {opacity: 0, y: 30},
            {
                opacity: 1,
                y: 0,
                duration: 0.8,
                ease: "power3.out",
                stagger: 0.2,
                scrollTrigger: {
                    trigger: textBlocksRef.current[0],
                    start: "top 80%",
                    toggleActions: "play none none none",
                },
            }
        );


        // Final paragraph animation
        gsap.fromTo(
            paragraphRef.current,
            {opacity: 0, y: 50},
            {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: "power3.out",
                scrollTrigger: {
                    trigger: paragraphRef.current,
                    start: "top 80%",
                    toggleActions: "play none none none",
                },
            }
        );

    }, []);

    return (
        <div className="flex flex-col justify-center space-y-8 animate-text-float">
            <h1
                ref={titleRef}
                className="bg-gradient-to-b from-white via-gray-300 to-gray-500 inline-block text-transparent bg-clip-text text-2xl lg:text-3xl font-main text-center"
            >
                Who Are We
            </h1>

            <h3
                ref={introTextRef}
                className="text-base lg:text-xl text-primary text-center font-main w-4/5 self-center"
            >
                At Web3Decision, we are a dedicated team of professionals with deep expertise in finance, blockchain,
                machine learning, and Web3 technology. Our multidisciplinary team includes:
            </h3>

            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 text-center">
                {[
                    {content: "5 PhDs specializing in finance, blockchain, and machine learning, bringing a wealth of academic research and practical experience to the table."},
                    {content: "5 Master's degree software developers with a focus on smart contracts, Web3, and blockchain development, ensuring top-tier technology integration and innovation."},
                    {content: "3 Crypto influencers who have a strong presence in the blockchain community, helping drive awareness and adoption of Web3Decision’s mission and services."},
                ].map((item, index) => (
                    <div key={index}>
                        <p
                            ref={(el) => (textBlocksRef.current[index] = el)}
                            className="w-2/3 lg:w-full lg:text-left bg-gradient-to-b from-white via-gray-300 to-gray-500 inline-block text-transparent bg-clip-text text-base"
                        >
                            <span
                                className="text-primary font-bold">{item.content.split(' ')[0]}</span> {item.content.substring(item.content.indexOf(' '))}
                        </p>
                    </div>
                ))}
            </div>

            <div className="flex justify-center items-center pt-6">
                <p
                    ref={paragraphRef}
                    className="text-center lg:text-left bg-gradient-to-b from-white via-gray-300 to-gray-500 text-transparent bg-clip-text text-base"
                >
                    <span
                        className="font-main from-primary to-sky-700 bg-gradient-to-r text-transparent bg-clip-text font-bold">
                        Together,
                    </span>{" "}
                    we are committed to building a{" "}
                    <span
                        className="font-main from-green-500 to-primary bg-gradient-to-r text-transparent bg-clip-text font-bold">
                        secure, innovative,
                    </span>{" "}
                    and AI-powered ecosystem for the decentralized future.
                </p>
            </div>

            <Team/>
        </div>
    );
}
