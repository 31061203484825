import {useState} from "react";
import {ProjectCard} from "../Cards";
import {LeftMenu, RightMenu} from "../Menus";
import ConnectWalletModel from "../Popups/ConnectWalletModel";
import DisconnectModal from "../Popups/DisconnectModal";
import {truncateAddress} from "../../utils";
import {useAccount} from "wagmi";

export default function Header() {
    const {isConnected, address} = useAccount();
    const [modalOpen, setModalOpen] = useState(undefined);

    const truncatedAddress = truncateAddress(address);

    const [rightMenu, setRightMenu] = useState(false);
    const [leftMenu, setLeftMenu] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);

    // State for animation classes
    const [leftButtonAnim, setLeftButtonAnim] = useState(false);
    const [rightButtonAnim, setRightButtonAnim] = useState(false);

    // Toggle functions for menus
    const toggleLeftMenu = () => {
        setLeftMenu((prev) => !prev);
        setLeftButtonAnim(true); // Trigger animation
        setTimeout(() => setLeftButtonAnim(false), 300); // Reset animation class
    };

    const toggleRightMenu = () => {
        setRightMenu((prev) => !prev);
        setRightButtonAnim(true); // Trigger animation
        setTimeout(() => setRightButtonAnim(false), 300); // Reset animation class
    };

    const toggleMobileMenu = () => {
        setMobileMenu((prev) => !prev);
    };

    const openPdf = () => {
        window.open(`WhitePaper.pdf`, "_blank");
    };

    return (
        <header className="z-50 fixed w-full">
            {/* Mobile Menu */}
            {mobileMenu && (
                <div className="h-screen bg-background flex space-x-8">
                    <div className="w-2 bg-primary h-screen"/>
                    <div className="flex flex-col space-y-8 pt-8 flex-1 pr-4 overflow-y-scroll">
                        <div className="flex justify-between">
                            <h1 className="text-xl text-white font-main">Launch Tools</h1>
                            <button
                                onClick={toggleMobileMenu}
                                className="absolute top-3 right-3 text-2xl bg-primary p-2 rounded-lg text-background active:text-primary active:bg-background hover:text-mcolor transition-colors duration-300 ease-in-out"
                            >
                                &#10005; {/* Close button */}
                            </button>
                        </div>

                        {/* Project Cards */}
                        <div className="grid grid-cols-1 gap-2">
                            <ProjectCard
                                title="Token Creation"
                                link="/create-token"
                                color="blue"
                            />
                            <ProjectCard title="Token Lock" link="/lock-token" color="red"/>
                            <ProjectCard title="Stake" link="/stake" color="green"/>
                            <ProjectCard title="Sale" link="/sale" color="yellow"/>
                        </div>
                        <button
                            onClick={() => [setModalOpen(isConnected ? "disconnect" : "connect"), setMobileMenu(false)]}
                            className="bg-primary text-black text-xl   py-4  w-full rounded-xl font-semibold hover:bg-primary hover:text-black transition-colors duration-300 ease-in-out"
                        >
                            {isConnected ? "Disconnect" : "Connect"} wallet
                        </button>

                        {/* AI Tools */}
                        <div className="flex flex-col space-y-4">
                            <a href="#" className="text-xl text-white font-main">
                                AI Tools
                            </a>
                            <a href="#" className="text-xl text-white font-main">
                                AI Marketplace
                            </a>
                        </div>

                        <Section title="Grants">
                            <LinkItem mainSection="grants" url="students-grants-section" title="Students"
                                      closeMenu={() => setMobileMenu(false)}/>
                            <LinkItem mainSection="grants" url="research-grants-section" title="Researchers"
                                      closeMenu={() => setMobileMenu(false)}/>
                            <LinkItem mainSection="grants" url="startup-grants-section" title="Startups"
                                      closeMenu={() => setMobileMenu(false)}/>
                            <LinkItem mainSection="grants" url="influencer-grants-section" title="Influencers"
                                      closeMenu={() => setMobileMenu(false)}/>
                        </Section>

                        {/* Partnerships Section
                        <Section title="Partnership">
                            <LinkItem mainSection="partnership" url="partners" title="Partners" />
                            <LinkItem mainSection="partnership" url="partnership-application"
                                title="Partnership Application" />
                        </Section> */}

                        {/* About Us Section */}
                        <Section title="About Us">
                            <LinkItem mainDiv={true} url="team" title="Team" closeMenu={() => setMobileMenu(false)}/>
                            <LinkItem funtional={true} onClick={openPdf} url="whitepaper.pdf"
                                      title="Whitepaper"/>
                            <LinkItem url="https://docs.web3decision.com/" title="Documents"/>
                            <LinkItem url="PitchDeck.pdf" title="Pitch Deck"/>
                        </Section>

                        {/* In Press Section
                        <Section title="In Press">
                            <LinkItem mainSection="inpress" url="blockchain-news" title="Blockchain News" />
                            <LinkItem mainSection="inpress" url="journal-news" title="Journal News" />
                        </Section> */}
                    </div>
                </div>
            )}

            {/* Mobile Toggle Button */}
            <div className=" lg:hidden pl-8 py-2 h-full bg-gradient-to-b from-background to-primary/10 ">
                <div
                    onClick={toggleMobileMenu}
                    className="flex items-center justify-center h-14 w-16 bg-cardbackground rounded-xl border-primary border-2"
                >
                    <img
                        className="w-6 h-12"
                        src="/images/leftMenuArrow.png"
                        alt="Left Arrow"
                    />
                </div>
            </div>

            {/* Desktop Menu */}
            <div
                className="hidden lg:flex lg:justify-between lg:items-center font-main sticky top-0 w-full p-4 z-10 bg-gradient-to-b from-background">

                <div className="flex space-x-6 ">
                    <div className="col-span-2 flex flex-col items-center space-y-4 ">
                        <div
                            onClick={toggleLeftMenu}
                            className={`flex items-center justify-center h-14 w-16 bg-cardbackground rounded-xl border-primary border-2 ${leftButtonAnim ? "animate-scale" : ""
                            }`}
                        >
                            <img className="w-6 h-12" src="/images/leftMenuArrow.png" alt="Left Arrow"/>
                        </div>
                        {leftMenu && <LeftMenu/>}


                    </div>

                    {address ? (
                        <div className="bg-cardbackground rounded-xl border-primary border-2  p-4">

                            <p className="text-xs text-white lg:text-sm text-center     ">{truncatedAddress}</p>

                        </div>
                    ) : null}
                </div>


                <div className="col-span-8 justify-center flex flex-col lg:flex-row items-center lg:space-x-4 ">
                    <div
                        className="bg-cardbackground rounded-xl border-primary border-2  p-4 space-y-4 lg:space-y-0 lg:space-x-4">
                        <LinkItem mainDiv={true} url="" title="Home"/>
                        <LinkItem mainDiv={true} url="team" title="Team"/>
                        <LinkItem mainDiv={true} url="web3d" title="$WEB3D"/>
                        <LinkItem url="#" title="AI Marketplace"/>


                    </div>

                </div>

                <div
                    className="col-span-2 justify-center flex flex-col lg:flex-row items-center space-y-4 lg:space-y-0 lg:space-x-2">
                    <button
                        onClick={() => setModalOpen(isConnected ? "disconnect" : "connect")}
                        className="bg-[#0F1013] text-primary border-2 border-primary md:w-42 h-14 w-40 rounded-xl font-semibold hover:bg-primary hover:text-black transition-colors duration-300 ease-in-out"
                    >
                        {isConnected ? "Disconnect" : "Connect"} wallet
                    </button>
                    <div
                        onClick={toggleRightMenu}
                        className={`flex items-center justify-center h-14 w-16 bg-cardbackground rounded-xl border-primary border-2 ${rightButtonAnim ? "animate-scale" : ""
                        }`}
                    >
                        <img className="w-6 h-12" src="/images/rightMenuArrow.png" alt="Right Arrow"/>
                    </div>
                    {rightMenu && <RightMenu/>}
                </div>

            </div>

            <ConnectWalletModel
                isOpen={modalOpen === "connect"}
                onClose={() => setModalOpen(undefined)}
            />
            <DisconnectModal
                isOpen={modalOpen === "disconnect"}
                onClose={() => setModalOpen(undefined)}
            />
        </header>
    );
}

// Reusable Section Component
const Section = ({title, children}) => (
    <div className="flex flex-col space-y-2">
        <h1 className="text-xl text-white font-main">{title}</h1>
        <div className="flex flex-col space-y-2">{children}</div>
    </div>
);

const LinkItem = ({title, url, mainSection, mainDiv, closeMenu}) => {
    const baseDomain = `${window.location.origin}`;

    const isExternal = url?.startsWith('http');

    const href = isExternal
        ? url
        : mainDiv
            ? `${baseDomain}/#${url}`
            : mainSection
                ? `${baseDomain}/${mainSection.toLowerCase()}/#${url}`
                : `${baseDomain}/${url}`;

    const handleClick = () => {
        if (closeMenu) {
            closeMenu(); // Close the menu if the function is provided
        }
    };

    return (
        <a
            href={href}
            target={isExternal ? "_blank" : "_self"}
            rel={isExternal ? "noopener noreferrer" : ""}
            onClick={handleClick}
            className="text-primary font-main font-light text-lg hover:text-white"
        >
            {title}
        </a>
    );
};


