import TeamCard from "../Cards/teamCard";
import {useEffect, useRef} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Team() {
    const developmentRef = useRef(null);
    const marketingRef = useRef(null);
    const designRef = useRef(null);
    const staffRef = useRef(null);

    useEffect(() => {
        const createScrollAnimation = (ref) => {
            return gsap.timeline({
                scrollTrigger: {
                    trigger: ref.current,
                    start: "top 90%",
                    end: "bottom 20%",
                    scrub: true,
                },
            }).fromTo(
                ref.current.children,
                {opacity: 0, y: 20},
                {opacity: 1, y: 0, duration: 0.5, stagger: 0.1, ease: "power2.out"}
            );
        };

        const developmentTimeline = createScrollAnimation(developmentRef);
        const marketingTimeline = createScrollAnimation(marketingRef);
        const staffTimeline = createScrollAnimation(staffRef);
        const designTimeline = createScrollAnimation(designRef);

        return () => {
            ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
        };
    }, []);

    const teamMembers = [
        {
            type: "development",
            image: "/images/team/winnerose.jpg",
            title: "Burak Osman Yaldız",
            description: "Fullstack Developer",
            profileLinks: {
                github: "https://github.com/WinneRose",
                linkedin: "https://www.linkedin.com/in/winnerose/"
            }
        },
        {
            type: "development",
            image: "/images/team/adeelnawaz.jpg",
            title: "Adeel Nawaz",
            description: "Blockchain Developer",
            profileLinks: {
                stackoverflow: "https://stackoverflow.com/users/2370970/adeel-nawaz"
            }
        },
        {
            type: "development",
            image: "/images/team/guneydirim.jpg",
            title: "Güney Dirim",
            description: "Fullstack Developer",
            profileLinks: {
                linkedin: "https://www.linkedin.com/in/g%C3%BCney-dirim-5972b7b9/",
                github: "https://github.com/passarante",
            }
        },
        {
            type: "development",
            image: "/images/team/skylords.jpg",
            title: "Sky Lords",
            description: "Legal & Lawyer",
            profileLinks: {
                telegram: "https://t.me/SkyLords"
            }
        },

        {
            type: "design",
            image: "/images/team/murali.jpg",
            title: "MurAli",
            description: "Design and Content Expert",
            profileLinks: {
                linkedin: "https://www.linkedin.com/in/murali-m-a6b255187?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
                instagram: "https://www.instagram.com/hashlet_digital/profilecard/?igsh=MW1tdnUwZ3U4dHJhYQ=="
            }
        }
    ];

    const groupedMembers = teamMembers.reduce((acc, member) => {
        acc[member.type] = acc[member.type] || [];
        acc[member.type].push(member);
        return acc;
    }, {});
    return (
        <div id="team" className="flex w-full flex-1 flex-col space-y-16">
            {/* CEO / Leader Card */}
            <TeamCard
                title="Selçuk Topal"
                description="Ceo&Founder, Web3&AI Developer"
                type="staff"
                image="/images/team/selcuktopal.jpg"
                profileLinks={{
                    x: "https://x.com/selcuktopal80",
                    linkedin: "https://www.linkedin.com/in/selcuktopal80/",
                }}
            />

            {/* Staff Team Section */}
            <div ref={staffRef}>
                <div className="flex flex-col lg:flex-row gap-8 w-full">
                    {groupedMembers.staff?.map((member) => (
                        <TeamCard
                            key={member.id || member.title} // Use a unique key
                            type={member.type}
                            image={member.image}
                            title={member.title}
                            description={member.description}
                            profileLinks={member.profileLinks}
                        />
                    ))}
                </div>
            </div>

            {/* Development Team Section */}
            <div ref={developmentRef}>
                <div className="flex flex-col lg:flex-row gap-8">
                    {groupedMembers.development?.map((member) => (
                        <TeamCard
                            key={member.id || member.title} // Use a unique key
                            type={member.type}
                            image={member.image}
                            title={member.title}
                            description={member.description}
                            profileLinks={member.profileLinks}
                        />
                    ))}
                </div>
            </div>

            {/* Design Team Section */}
            <div ref={designRef}>
                <div className="flex flex-col lg:flex-row gap-8">
                    {groupedMembers.design?.map((member) => (
                        <TeamCard
                            key={member.id || member.title} // Use a unique key
                            type={member.type}
                            image={member.image}
                            title={member.title}
                            description={member.description}
                            profileLinks={member.profileLinks}
                        />
                    ))}
                </div>
            </div>

            {/* Marketing Team Section */}
            <div ref={marketingRef}>
                <div className="flex flex-col lg:flex-row gap-8 w-full">
                    {groupedMembers.marketing?.map((member) => (
                        <TeamCard
                            key={member.id || member.title} // Use a unique key
                            type={member.type}
                            image={member.image}
                            title={member.title}
                            description={member.description}
                            profileLinks={member.profileLinks}
                        />
                    ))}
                </div>
            </div>
        </div>
    );

}
