import {SocialIcon} from "react-social-icons";
import {motion} from "framer-motion";
import React from "react";

export default function TeamCard({
                                     title,
                                     description,
                                     image,
                                     type,
                                     profileLinks = {},
                                     id,
                                 }) {
    const colorClasses = {
        marketing: {
            background: "bg-marketingc",
            border: "border-yellow-400",
            gradient: "from-yellow-400",
        },
        development: {
            background: "bg-developmentc",
            border: "border-red-600",
            gradient: "from-red-600",
        },
        staff: {
            background: "bg-staffc",
            border: "border-green-400",
            gradient: "from-green-400",
        },
    };

    const colorClass = colorClasses[type] || {
        border: "border-blue-400",
        gradient: "from-blue-400",
    };

    return (
        <motion.div
            className={` flex flex-col border-2 ${colorClass.border} py-8 rounded-xl items-center justify-center sm:w-full relative group overflow-hidden`}
            key={id}
            whileHover={{scale: 1.05}}
            transition={{duration: 0.5, ease: "easeInOut"}}
        >
            {/* Animated Gradient Background (Only on Hover) */}
            <motion.div
                className={`absolute inset-0 blur-lg opacity-0 z-0 rounded-xl bg-gradient-to-b ${colorClass.gradient}`}
                whileHover={{opacity: 0.5}}
                transition={{duration: 0.7, ease: "easeInOut"}}
            />

            {/* Image Animation */}
            <motion.div
                className="relative flex items-center justify-center overflow-hidden h-[20vh] lg:h-[30vh] mb-10 z-10"
                initial={{scale: 0.8, opacity: 0}}
                animate={{scale: 1, opacity: 1}}
                transition={{duration: 1}}
            >
                <img
                    className="border-2 border-cyan-300/50 z-10 w-44 h-44 relative transition-transform duration-500 ease-in-out hover:scale-110 rounded-xl object-cover shadow-lg"
                    src={image}
                    alt={title}
                />
            </motion.div>

            {/* Title Animation */}
            <motion.h1
                className="text-xl font-extrabold text-center text-white mb-2 z-10"
                initial={{y: -20, opacity: 0}}
                animate={{y: 0, opacity: 1}}
                transition={{duration: 0.5, delay: 0.5}}
            >
                {title}
            </motion.h1>

            <>
                <motion.div
                    className=" absolute right-1/2 bottom-1/2 bg-gradient-to-b from-transparent to-cyan-500 translate-y-[14px] w-px h-48  blur-[2px]"/>
                <motion.div
                    className="absolute right-1/2 bottom-1/2 bg-gradient-to-b from-transparent to-cyan-500 translate-y-[14px] w-px h-48  "/>
                <motion.div
                    className="absolute right-1/2 translate-x-[1.5px] bottom-1/2 bg-cyan-600 translate-y-[14px] w-[4px] h-[4px] rounded-full z-40 blur-[3px]"/>
                <motion.div
                    className="absolute right-1/2 translate-x-[0.5px] bottom-1/2 bg-cyan-300 translate-y-[14px] w-[2px] h-[2px] rounded-full z-40 "/>
            </>
            {/* Description Animation */}
            <motion.p
                className="text-base text-center text-gray-300 px-4 z-10"
                initial={{y: 20, opacity: 0}}
                animate={{y: 0, opacity: 1}}
                transition={{duration: 0.5, delay: 0.7}}
            >
                {description}
            </motion.p>

            {/* Social Links Animation */}
            <motion.div
                className="flex items-center justify-center mt-7 z-10 space-x-4"
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 0.8, delay: 1}}
            >
                {Object.entries(profileLinks).map(([platform, link]) => (
                    <a
                        key={platform}
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`p-2 rounded-full border ${colorClass.border}  hover:border-white transition-transform duration-300 ease-in-out hover:scale-110`}
                    >
                        <SocialIcon network={platform} style={{height: 36, width: 36}}/>
                    </a>
                ))}
            </motion.div>
        </motion.div>
    );
}
