import {useEffect, useRef} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default function Grants() {
    const headerRef = useRef(null);
    const sectionRefs = useRef([]);

    useEffect(() => {
        // Header animation
        gsap.fromTo(
            headerRef.current,
            {opacity: 0, y: -50},
            {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: "power1.out",
                scrollTrigger: {
                    trigger: headerRef.current,
                    start: "top 80%",
                    toggleActions: "play none none none",
                },
            }
        );


        // Section animations
        sectionRefs.current.forEach((section, index) => {
            gsap.fromTo(
                section,
                {opacity: 0, y: 30},
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.8,
                    ease: "power3.out",
                    stagger: 0.2,
                    scrollTrigger: {
                        trigger: section,
                        start: "top 80%",
                        toggleActions: "play none none none",
                    },
                }
            );
        });
    }, []);

    return (
        <div
            className="flex flex-col space-y-12 rounded-2xl px-4 lg:px-16 py-10 bg-gradient-to-br from-black via-gray-900 to-primary/20 min-h-screen">
            {/* Header Section */}
            <div
                ref={headerRef} // Reference for the header
                className="h-[20vh] flex w-full justify-center items-center bg-gradient-to-br to-primary/60 from-cardbackground rounded-lg border border-transparent shadow-lg"
            >
                <h1 className="font-main bg-gradient-to-b from-white via-gray-300 to-gray-600 text-transparent bg-clip-text font-bold text-3xl lg:text-5xl tracking-wide">
                    Grants
                </h1>
            </div>

            {/* Grants Sections */}
            <div className="flex flex-col space-y-12 lg:space-y-16">
                {[
                    {
                        title: "Students Grants",
                        content:
                            "Web3D Student Grants aim to empower the next generation of blockchain and AI innovators. These grants support students working on cutting-edge Web3 projects, offering financial resources and mentorship to help them bring their ideas to life.",
                    },
                    {
                        title: "Research Grants",
                        content:
                            "Web3D Researcher Grants are designed to support scholars and professionals conducting research in blockchain, AI, and decentralized technologies. These grants provide funding and resources to advance cutting-edge research projects.",
                    },
                    {
                        title: "Startup Grants",
                        content:
                            "Web3D Startup Grants aim to foster innovation and entrepreneurship in the Web3 and blockchain sectors. These grants support early-stage startups working on decentralized applications and AI-driven projects.",
                    },
                    {
                        title: "Influencers Grants",
                        content:
                            "Web3D Influencer Grants are designed to support crypto and blockchain influencers passionate about educating and spreading awareness in the decentralized space.",
                    },
                    {
                        title: "Web3 Community Grants",
                        content:
                            "Rewards are designed to recognize and reward active participation within the Web3D ecosystem. Community members can earn Web3D tokens and gain access to exclusive benefits.",
                    },
                ].map((item, index) => (
                    <div
                        key={index}
                        ref={(el) => (sectionRefs.current[index] = el)} // Reference for each section
                        className="bg-opacity-50 backdrop-blur-lg bg-white/10 border border-gray-800 rounded-lg p-8 space-y-4 shadow-xl transform hover:scale-105 transition duration-300 ease-in-out"
                    >
                        <h1 className="text-xl lg:text-3xl font-bold text-white font-main">{item.title}</h1>
                        <p className="text-gray-300 text-sm lg:text-lg leading-relaxed">
                            {item.content}
                        </p>
                    </div>
                ))}

               
            </div>
        </div>
    );
}
