import { ethers } from "ethers";
import StakingABI from "./abi/XStaking.json";
import moment from 'moment'

const staticProvider = new ethers.providers.JsonRpcProvider(
  "https://bsc-dataseed.binance.org/"
);
export class XStaking {
  contract;

  constructor(signer, contractAddress) {
    this.contract = new ethers.Contract(contractAddress, StakingABI, signer);
  }
  stake = async (pid , amount , selectedDuration) => {
    const receipt = await this.contract.deposit(amount , pid , selectedDuration);
    const res = await receipt.wait();
    console.log(receipt, res);
    console.log("receipt res");
  };

  userDeposited =  async (pid , user) => {
    var orders = await this.contract.userOrderIds(user);
    let totalAmount = 0;
    if(orders && orders.length > 0){
      for(let o of orders){
        console.log("o" , o)
        var getOrder = await this.contract.orders(o);
        if(getOrder && Number(getOrder.pId) == Number(pid))
        {
          console.log("order pool :" , Number(o.pId))
          totalAmount = totalAmount + (Number(getOrder.amount) / 10 ** 18)
          console.log("pending : " , (Number(getOrder.amount) / 10 ** 18));
        }
      }
    }
    if(totalAmount > 0){
      totalAmount = Number(totalAmount).toFixed(3)
    }
    return totalAmount ;
  };
  getPending =  async (user ,pid) => {
    var orders = await this.contract.userOrderIds(user);
    let totalPending = 0;
    if(orders && orders.length > 0){
      for(let o of orders){
        var getOrder = await this.contract.orders(o);
        if(getOrder && Number(getOrder.pId) == Number(pid))
        {
          console.log("order pool :" , Number(o.pId))
          let pending =  await this.contract.pendingRewards(o);
          totalPending = totalPending + (Number(pending) / 10 ** 18)
          console.log("pending : " , (Number(pending) / 10 ** 18));
        }
      }
    }
    if(totalPending > 0){
      totalPending = Number(totalPending).toFixed(3)
    }
    console.log("orders : ", orders)
    return totalPending ;
  
  };
  isLocked =  async (user) => {
    var orders = await this.contract.userOrderIds(user);
    let lock = true;
    if(orders && orders.length > 0){
      for(let o of orders){
        let order =  await this.contract.orders(o);
        if(moment().isAfter(moment.unix(Number(order.nextUnlock)))){
          lock = false;
          break
        }
        console.log("unlock date : ",Number(order.nextUnlock) , moment().isAfter(moment.unix(Number(order.nextUnlock))))
      }
    }
    console.log("lock : ", lock)
    return lock ;
  
  };
  withdraw = async (user) => {
    var orders = await this.contract.userOrderIds(user);
    if(orders && orders.length > 0){
      for(let o of orders){
        let order =  await this.contract.orders(o);
        if(moment().isAfter(moment.unix(Number(order.nextUnlock)))){
          const receipt = await this.contract.claimRewards(o);
          const res = await receipt.wait();
          console.log(receipt, res);
          console.log("receipt res");
          break
        }
      }
    }
  };
}
