import React from "react";
import "./css/Web3TokenInfo.css";

const Web3TokenInfo = () => {
    const data = {
        Token: "DynamicTaxToken",
        Name: "Web3D Token",
        Symbol: "Web3D",
        Decimals: 18,
        Supply: "1000000000",
        Network: "BSC(BEP-20) Token",
        dexRouter: "PancakeSwapV2",
        marketingWallet: "0x9afa4c83b3C2f22A10E803dD075B513C81F1eD54",
        contractAddress: "0x7eD9054C48088bb8Cfc5C5fbC32775b9455A13f7",
    };

    return (
        <div className="token_info">
            <div className="glass-card">
                <div className="header">
                    <h1 class="roadmap-title text-primary text-3xl lg:text-6xl font-main mb-14 mt-5">
                        Deployed Web3D Token Information
                    </h1>
                </div>
                <div className="table-container">
                    <table className="token-info-table">
                        <tbody>
                        {Object.keys(data).map((key) => (
                            <tr key={key}>
                                <td className="key">{key}</td>
                                <td className="value">
                                    {key === "contractAddress" ? (
                                        <a
                                            href="https://bscscan.com/address/0x7eD9054C48088bb8Cfc5C5fbC32775b9455A13f7"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="contract-link"
                                        >
                                            {data[key]}
                                        </a>
                                    ) : (
                                        data[key]
                                    )}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Web3TokenInfo;
