import {useState} from "react";

export default function RightMenu() {
    const [isVisible, setIsVisible] = useState(true); // State to manage visibility

    const toggleMenu = () => {
        setIsVisible((prev) => !prev); // Toggle visibility directly
    };

    // Render nothing if not visible
    if (!isVisible) return null;

    return (
        <div>
            <div
                className="animate-fade-right bg-cardbackground border-primary border h-screen w-64 absolute top-0 right-0 p-8 flex flex-col space-y-8"
            >
                <button
                    onClick={toggleMenu}
                    className="bg-primary hover:scale-90 active:scale-105 hover:bg-background hover:text-primary hover:border-2 hover:border-primary text-background text-xl rounded p-2 mb-4"
                >
                    &#10005; {/* Close icon */}
                </button>

                {/* Grants Section */}
                <Section title="Grants">
                    <LinkItem mainSection="grants" url="students-grants-section" title="Students"/>
                    <LinkItem mainSection="grants" url="research-grants-section" title="Researchers"/>
                    <LinkItem mainSection="grants" url="startup-grants-section" title="Startups"/>
                    <LinkItem mainSection="grants" url="influencer-grants-section" title="Influencers"/>
                </Section>

                {/* Partnerships Section
                <Section title="Partnership">
                    <LinkItem mainSection="partnership" url="partners" title="Partners"/>
                    <LinkItem mainSection="partnership" url="partnership-application" title="Partnership Application"/>
                </Section> */}

                {/* About Us Section */}
                <Section title="About Us">
                    <LinkItem mainSection="" url="team" title="Team"/>
                    <LinkItem url="WhitePaper.pdf" title="Whitepaper"/>
                    <LinkItem url="https://docs.web3decision.com/" title="Documents"/>
                    <LinkItem url="PitchDeck.pdf" title="Pitch Deck"/>
                </Section>

                {/* In Press Section
                <Section title="In Press">
                    <LinkItem mainSection="inpress" url="blockchain-news" title="Blockchain News"/>
                    <LinkItem mainSection="inpress" url="journal-news" title="Journal News"/>
                </Section> */}
            </div>
        </div>
    );
}

// Reusable Section Component
const Section = ({title, children}) => (
    <div className="flex flex-col space-y-2">
        <h1 className="text-xl text-white font-main">{title}</h1>
        <div className="flex flex-col space-y-2">{children}</div>
    </div>
);

// Reusable LinkItem Component
const LinkItem = ({title, url, mainSection}) => {
    // Get the base domain (main domain only)
    const baseDomain = `${window.location.origin}`;

    // Check if the URL is external by looking for "http" at the start of the URL
    const isExternal = url?.startsWith('http');

    // Construct href depending on whether it's an external link, internal, or fallback
    const href = url
        ? (isExternal
            ? url // Use external link as-is
            : mainSection
                ? `${baseDomain}/${mainSection.toLowerCase()}/#${url}` // Internal link with base URL
                : `${baseDomain}/${url}`) // Ensure absolute path
        : `${baseDomain}/url`; // Fallback to base domain if url and mainSection are not provided

    return (
        <a
            href={href}
            target={isExternal ? "_blank" : "_self"} // Open external links in a new tab
            rel={isExternal ? "noopener noreferrer" : ""} // Security for external links
            className="text-primary font-main font-light text-lg hover:text-white"
        >
            {title}
        </a>
    );
};
