import {  ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export const formatNumber = (num) => {
    const formattedNumber = num.toLocaleString()

    return formattedNumber.replaceAll(',', ' ')
}

export const truncateAddress = (address) => {
    return address
        ? `${address.slice(0, 7)}...${address.slice(-7, -1)}${address.slice(-1)}`
        : ''
}



export function cn(...inputs) {
  return twMerge(clsx(inputs));
}
