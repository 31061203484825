
import metamaskImg from '../../assets/wallets/metamask.png'
import coinbaseImg from '../../assets/wallets/coinbase.png'
import walletConnectImg from '../../assets/wallets/wallet-connect.png'
import { useConnect } from 'wagmi'
import { toast } from 'react-hot-toast'
import MainModal from './MainModel'
import { Loader } from 'rsuite';
const wallets = [metamaskImg, coinbaseImg, walletConnectImg]


const ConnectWalletModel = ({ isOpen, onClose }) => {
    const { connect, connectors, isLoading, pendingConnector } = useConnect({
        onSuccess: () => {
            toast.success('Wallet connected successfully!')
            onClose()
        },
        onError: () => {
            toast.error('Something went wrong!')
        },
    })

    return (
        <MainModal isOpen={isOpen} onClose={onClose}>
            <h2 className="text-xl md:text-2xl text-white font-semibold mb-6 font-glaser">
                Connect your wallet
            </h2>
            <div className="flex gap-4 justify-center">
                {connectors.map((connector, index) => (
                    <button
                        key={connector.id}
                        className="btn-secondary w-full rounded-full flex flex-col items-center gap-2 px-2"
                        onClick={() => connect({ connector })}
                        disabled={!connector.ready}
                    >
                        <img
                            width={60}
                            height={60}
                            className='rounded-full'
                            src={wallets[index]}
                            alt={connector.name}
                        />
                        <h2 className="text-sm  text-white font-semibold mb-6 font-glaser">
                            {connector.name}
                        </h2>
                        {/* <h2 className="text-xs  text-white font-semibold mt-2 font-glaser">
                            {!connector.ready && ' (unsupported)'}
                        </h2> */}
                        {
                            isLoading && (
                                <h2 className="text-xs text-white font-semibold font-glaser">
                                    {connector.id === pendingConnector?.id && (
                                        <span>
                                            Connecting
                                            <span className="inline-block animate-pulse opacity-50">.</span>
                                            <span className="inline-block animate-pulse opacity-50 delay-200">.</span>
                                            <span className="inline-block animate-pulse opacity-50 delay-400">.</span>
                                        </span>
                                    )}
                                </h2>


                            )
                        }

                    </button>


                ))}

            </div>
        </MainModal>
    )
}

export default ConnectWalletModel
