import {useEffect, useRef} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {HwwCard} from "../Cards";

gsap.registerPlugin(ScrollTrigger); // Register the ScrollTrigger plugin

export default function HowWeWork() {
    const titleRef = useRef(null);
    const cardRefs = useRef([]);

    useEffect(() => {
        // Animate title when it comes into view
        gsap.fromTo(
            titleRef.current,
            {opacity: 0, y: 50},
            {
                opacity: 1,
                y: 0,
                duration: 1,
                ease: "power3.out",
                scrollTrigger: {
                    trigger: titleRef.current,
                    start: "top 80%", // Animation starts when the title is 80% in the viewport
                    toggleActions: "play none none none", // Play once
                },
            }
        );

        // Animate cards when they come into view
        gsap.fromTo(
            cardRefs.current,
            {opacity: 0, scale: 0.8, y: 30},
            {
                opacity: 1,
                scale: 1,
                y: 0,
                duration: 0.8,
                ease: "power3.out",
                stagger: 0.2,
                scrollTrigger: {
                    trigger: cardRefs.current[0], // Target the first card for trigger
                    start: "top 80%", // Animation starts when the first card is 80% in the viewport
                    toggleActions: "play none none none",
                },
            }
        );
    }, []);

    return (
        <div className="flex flex-col space-y-16">
            <h1
                ref={titleRef}
                className="bg-gradient-to-b from-white via-gray-300 to-gray-500 inline-block text-transparent bg-clip-text text-2xl lg:text-3xl font-main text-center"
            >
                Check How Web3 Decision Work
            </h1>

            <div className="flex flex-col self-center   gap-16 justify-items-center px-4">
                {[
                    {
                        title: "Research",
                        image: "/icons/search.svg",
                        decscription: "The Web3D Research Center is a hub for advancing knowledge and innovation in blockchain, AI, and decentralized technologies. The center aims to bring together researchers, developers, and thought leaders to explore new possibilities in Web3, smart contracts, and tokenomics. With a focus on pushing the boundaries of decentralized systems, the Web3D Research Center supports groundbreaking research projects, offering funding, resources, and a collaborative environment for scholars and innovators. It’s the place where the future of Web3 is shaped."
                    },
                    {title: "Metric", image: "/icons/statics.svg", decscription: ""},
                    {
                        title: "Community",
                        image: "/icons/person.svg",
                        decscription: " Rewards are designed to recognize and reward active participation within the Web3D ecosystem. By contributing to discussions, providing feedback, or helping others navigate the platform, community members can earn Web3D tokens and gain access to exclusive benefits. Whether you’re staking, referring new users, or sharing valuable insights, your involvement helps strengthen the decentralized Web3D network, and we believe in rewarding that contribution."
                    },
                ].map((item, index) => (
                    <div
                        key={index}
                        ref={(el) => (cardRefs.current[index] = el)} // Reference wrapper div for each card
                        className="w-full"
                    >
                        <HwwCard
                            title={item.title}
                            description={item.decscription}
                            image={item.image}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
