import { ethers } from "ethers";
import ERC20ABI from "./abi/ERC20.json";
const staticProvider = new ethers.providers.JsonRpcProvider(
  "https://bsc-dataseed.binance.org/"
);
export class ERC20 {
  contract;

  constructor(signer, contractAddress) {
    this.contract = new ethers.Contract(contractAddress, ERC20ABI, signer);
  }

  approve = async (spender) => {
    const receipt = await this.contract.approve(spender, ethers.constants.MaxUint256);
    const res = await receipt.wait();
    console.log(receipt, res);
    console.log("receipt res");
  };
  allowedAmount = async (owner , spender) => {
    var amount = await this.contract.allowance(owner , spender);
    amount = ethers.utils.formatEther(amount);
    amount = parseFloat(amount)
    return amount;
  };
  balance =  async (owner) => {
    var amount = await this.contract.balanceOf(owner);
    amount = ethers.utils.formatEther(amount);
    amount = parseFloat(amount)
    return amount;
  };
  mint = async (amount) => {
    const receipt = await this.contract.mint(amount);
    const res = await receipt.wait();
    console.log(receipt, res);
    console.log("receipt res");
  };
}
