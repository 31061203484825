"use client";
import React, {useEffect, useRef, useState} from "react";

import {
    Backers,
    Footer,
    Introduction,
    Roadmap,
    Socials,
    Tokenomics,
    Web3TokenInfo,
    WhoUs
} from "../../components/Sections"
import News from "../../components/Sections/news";
import {gsap} from 'gsap';


export default function Home() {
    const [isVisibleCountDown, setIsVisibleCountDown] = useState(true);
    const tradingSection = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({paused: true});

        // Animate the heading
        tl.fromTo(
            tradingSection.current,
            {opacity: 0, scale: 0.5},
            {opacity: 1, scale: 1, duration: 0.5, ease: "bounce.inOut"},
        )

        tl.play();
    }, []);
    return (
        <div className="flex flex-col space-y-16">
            {/* {isVisibleCountDown && (
                <PreSaleCountdown setIsVisibleCountDown={setIsVisibleCountDown} isVisibleCountDown={isVisibleCountDown} />
            )
             } */}
            <Introduction/>
            <div ref={tradingSection} className="flex flex-col space-y-4  items-center">
                <h1 className="text-amber-400 text-xl lg:text-2xl font-main">$Web3D Buy</h1>

                <div className="grid grid-cols-2 lg:flex-row w-full gap-4">
                    {/* Ave Card */}
                    <div
                        className=" backdrop-blur-md p-8 rounded-xl shadow-lg flex flex-col items-center border border-primary/40 flex-grow  ">
                        <img src="/icons/ave.png"
                             className="w-16 h-16 lg:h-24 lg:w-24"/>
                        <h2 className="text-xl font-semibold mt-4 text-white">Ave</h2>

                        <a href="https://ave.ai/token/0x7ed9054c48088bb8cfc5c5fbc32775b9455a13f7-bsc?from=Home"
                           target="_blank" rel="noopener noreferrer" className="mt-6">
                            <button
                                className="px-6 py-3 bg-white/10 rounded-full transition-transform duration-300 ease-in-out hover:bg-primary hover:scale-105 backdrop-blur-md group">
                                <span
                                    className="text-primary text-lg font-main font-bold group-hover:text-black transition-colors">
                                  BUY
                                </span>
                            </button>

                        </a>
                    </div>
                    {/* Dextools Card */}
                    <div
                        className=" backdrop-blur-md p-8 rounded-xl shadow-lg flex flex-col items-center border border-primary/40 flex-grow ">
                        <img src="/icons/dex.png"
                             className="w-16 h-16 lg:h-24 lg:w-24"/>
                        <h2 className="text-xl font-semibold mt-4 text-white">Dextools</h2>

                        <a href="https://www.dextools.io/app/en/bnb/pair-explorer/0x2667d77185ecce6755ef84a095e40974048b2289?t=1732638129144"
                           target="_blank" rel="noopener noreferrer"
                           className="mt-6">
                            <button
                                className="px-6 py-3 bg-white/10 rounded-full transition-transform duration-300 ease-in-out hover:bg-primary hover:scale-105 backdrop-blur-md group">
                                <span
                                    className="text-primary text-lg font-main font-bold group-hover:text-black transition-colors">
                                  BUY
                                </span>
                            </button>
                        </a>
                    </div>

                    {/* Pancake Card */}
                    <div
                        className=" backdrop-blur-md p-8 rounded-xl shadow-lg flex flex-col items-center border border-primary/40 flex-grow ">
                        <img src="/icons/pancake.png"
                             className="w-16 h-16 lg:h-24 lg:w-24"/>
                        <h2 className="text-xl font-semibold mt-4 text-white">Pancake</h2>

                        <a href="https://pancakeswap.finance/?outputCurrency=0x7eD9054C48088bb8Cfc5C5fbC32775b9455A13f7&inputCurrency=BNB"
                           target="_blank" rel="noopener noreferrer" className="mt-6">
                            <button
                                className="px-6 py-3 bg-white/10 rounded-full transition-transform duration-300 ease-in-out hover:bg-primary hover:scale-105 backdrop-blur-md group">
                                <span
                                    className="text-primary text-lg font-main font-bold group-hover:text-black transition-colors">
                                  BUY
                                </span>
                            </button>
                        </a>
                    </div>

                    {/* Pancake Card */}
                    <div
                        className=" backdrop-blur-md p-8 rounded-xl shadow-lg flex flex-col items-center border border-primary/40 flex-grow ">
                        <img src="/icons/finebox.png"
                             className="w-16 h-16 lg:h-24 lg:w-24"/>
                        <h2 className="text-xl font-semibold mt-4 text-white">Finebox</h2>

                        <a href="https://www.finexbox.com/market/pair/WEB3D-USDT.html"
                           target="_blank" rel="noopener noreferrer" className="mt-6">
                            <button
                                className="px-6 py-3 bg-white/10 rounded-full transition-transform duration-300 ease-in-out hover:bg-primary hover:scale-105 backdrop-blur-md group">
                                <span
                                    className="text-primary text-lg font-main font-bold group-hover:text-black transition-colors">
                                  BUY
                                </span>
                            </button>
                        </a>
                    </div>
                </div>


            </div>
            <div className="pt-20 flex flex-col space-y-16">
                <Backers/>
                <Socials/>
                <WhoUs/>
                <Tokenomics/>
                <Web3TokenInfo/>
                <Roadmap/>
                <News/>
            </div>
            <Footer/>
        </div>
    );
}