import React, { FC, FormEvent, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useAccount } from 'wagmi'
import { useSigner } from 'wagmi'
import { ERC20 } from '../../contracts/ERC20'
import { XStaking } from '../../contracts/XStaking'
import MainModal from '../Popups/MainModel'

const StakingPopup = ({ isPopupOpen, onClose, onSubmitForm, title, balance, min, max }) => {
  const [amount, setAmount] = useState('')
  const [selectedDuration, setSelectedDuration] = useState(30) // Default to 30 Days
  const { address, isConnected } = useAccount()
  const { data: signer } = useSigner()
  const [selectedLock, setSelectedLock] = useState(30)  // Add state to track the selected lock period

  function isNumber(value) {
    return ((value != null) &&
      (value !== '') &&
      !isNaN(Number(value.toString())));
  }

  const handleMax = async () => {
    if (!signer) return
    const coinContract = new ERC20(signer, '0x7ed9054c48088bb8cfc5c5fbc32775b9455a13f7')
    const balance = await coinContract.balance(address)
    setAmount(balance)
  }

  const handleStake = async (e) => {
    e.preventDefault()
    if (!signer) return
    const coinContract = new ERC20(signer, '0x7ed9054c48088bb8cfc5c5fbc32775b9455a13f7')
    const stakingContract = new XStaking(signer, '0xa8c51818d9c95b648b1105033139b696be1d7091')
    const balance = await coinContract.balance(address)
    console.log("amounts :", amount, min, max)
    if (!amount || !isNumber(amount)) {
      toast.error('Please Enter correct amount of tokens')
      return
    }
    if (Number(amount) < 0.001) {
      toast.error('Please Enter amount greater than 0.01')
      return
    }
    if (Number(amount) > Number(balance)) {
      toast.error('Entered amount greater than balance')
      return
    }
    if (Number(amount) > Number(max.replace(/_/g, "")) || Number(amount) < Number(min.replace(/_/g, ""))) {
      toast.error(`Please Enter Amount Minimum ${min.replace(/_/g, "")} Maximum ${max.replace(/_/g, "")}`)
      return
    }

    // Pass selected duration along with the amount
    onSubmitForm(Number(amount), selectedDuration)
    onClose();
  }

  return (
    <MainModal isOpen={isPopupOpen} onClose={onClose}>
      <h2 className="text-xl text-white font-bold mb-4">{title}</h2>
      <p className="text-sm font-medium text-alt mb-6"></p>
      <form onSubmit={handleStake} className="flex flex-col gap-3">
        <label htmlFor="address" className="text-white text-base">
          Your WEB3D Balance :  {balance}
        </label>
        <div className="relative mb-4 flex flex-wrap items-stretch">
          <input
            id="amount"
            type="text"
            onChange={e => setAmount(e.target.value)}
            value={amount}
            className="relative m-0 -mr-0.5 block w-[1px] min-w-0 flex-auto rounded-l border border-solid border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-white font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none "
            placeholder="Enter WEB3D amount"
            aria-label="Enter WEB3D amount"
            aria-describedby="button-addon2"
          />
          <button
            className="z-[2] inline-block rounded-r bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-black shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:z-[3] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] "
            data-te-ripple-init
            type="button"
            onClick={handleMax}
            id="button-addon2"
          >
            Max
          </button>
        </div>

        <div className="">
          <label for="lock" className="block text-sm/6 font-medium text-white">Select Lock</label>
          <div className="mt-2 ">
            <select
              id="lock"
              name=""
              autoComplete=""
              className="block w-full min-w-0 flex-auto rounded border border-solid border-neutral-300 bg-transparent text-white dark:bg-transparent px-3 py-2  text-white font-normal leading-[1.6] outline-none transition duration-200 ease-in-out focus:z-[3] focus:border-primary focus:shadow-[inset_0_0_0_1px_rgb(59,113,202)] focus:outline-none"
            >
              <option className="bg-white  text-neutral-700 !important" value={30}>30 Days</option>
              <option className="bg-white  text-neutral-700 " value={60}>60 Days</option>
              <option className="bg-white  text-neutral-700 " value={90}>90 Days</option>
              <option className="bg-white  text-neutral-700 " value={120}>120 Days</option>
              <option className="bg-white  text-neutral-700 " value={240}>240 Days</option>
              <option className="bg-white  text-neutral-700 " value={270}>270 Days</option>
            </select>
          </div>
        </div>


       

        <div className="flex  gap-2 w-full justify-between mt-2 mb-2">
          <button onClick={handleStake} className="bg-[#0F1013] text-primary  border-2 border-primary  h-10 w-[130px] rounded-xl font-semibold hover:bg-primary hover:text-black transition-colors duration-300 ease-in-out">
            Stake
          </button>
          <button onClick={onClose} className="bg-[#0F1013] text-primary  border-2 border-primary  h-10 w-[130px] rounded-xl font-semibold hover:bg-primary hover:text-black transition-colors duration-300 ease-in-out">
            Cancel
          </button>
        </div>

      </form>
    </MainModal>
  )
}

export default StakingPopup;
