import { useDisconnect } from 'wagmi'
import { toast } from 'react-hot-toast'

import MainModal from './MainModel'


const DisconnectModal = ({ isOpen, onClose }) => {
  const { disconnect, isLoading } = useDisconnect({
    onSuccess: () => {
      onClose()
      toast.success('Wallet disconnected successfully!')
    },
    onError: () => {
      toast.error('Something went wrong!')
    },
  })

  return (
    <MainModal isOpen={isOpen} onClose={onClose}>
      <p className="text-white text-xl md:text-2xl font-medium mb-4">
        Disconnect
      </p>
      <p className="font-bold text-white text-lg mb-8">
        Are you sure that you want to disable your wallet?{' '}
      </p>
      <div className="flex items-center gap-8 justify-center">
        <button
          onClick={() => disconnect()}
          className="bg-white text-black border border-primary md:w-48 px-4 py-2 rounded-md font-semibold hover:bg-primary hover:text-black transition-colors duration-300 ease-in-out"
        >
          {isLoading ? 'Disconnecting' : 'Disconnect'}
        </button>
        <button onClick={onClose} className="bg-white text-black border border-primary md:w-48 px-4 py-2 rounded-md font-semibold hover:bg-primary hover:text-black transition-colors duration-300 ease-in-out">
          Cancel
        </button>
      </div>
    </MainModal>
  )
}

export default DisconnectModal
