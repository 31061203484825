export const workExperience = [
    {
        id: 1,
        title: "AI-powered application developed to rate blockchain security",
        date: "05 - October 2024",
        webName: "Anadolu Ajansi",
        className: "md:col-span-2",
        path: 'https://www.aa.com.tr/tr/insana-dair/blokzincir-guvenligini-derecelendiren-yapay-zeka-destekli-uygulama-gelistirildi/3352157'
    },
    {
        id: 2,
        title: "AI-powered application developed to rate blockchain security",
        date: "05 - October 2024",
        webName: "Eurovizyon",
        className: "md:col-span-2",
        path: 'https://www.eurovizyon.co.uk/blokzincir-guvenligini-derecelendiren-yapay-zeka-destekli-uygulama-gelistirildi'

    },
    {
        id: 3,
        title: "Artificial Intelligence-Backed Blockchain Security Application Developed",
        date: "05 - October 2024",
        webName: "İzmir Gündem Haber",
        className: "md:col-span-2",
        path: 'https://www.izmirgundemhaber.com/teknoloji/yapay-zeka-destekli-blokzincir-guvenlik-uygulamasi-gelistirildi-6029h'

    },

];



